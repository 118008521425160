import {
	IonRippleEffect,
	IonRow,
	IonCol,
	IonCard,
	IonCardContent,
	IonButton,
} from "@ionic/react";
import React from "react";
import Sketch from "react-p5";
//import firebase from '../FirebaseConfig';

class Flowers extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: "https://i.imgur.com/gaP5Vu7.jpg",
		};
		this.CRYSTAL_SIZE = 100;
		this.SIDES = 6;
		this.XREPEATNUM = 2;
		this.YREPEATNUM = 3;
		this.GAP = 50;
		this.PALETTE = [];
		this.DEFAULT_ANGLE = 360 / 6;
		this.CANVAS_DIMENSIONS_X =
			this.CRYSTAL_SIZE * this.XREPEATNUM +
			this.GAP * (this.XREPEATNUM - 1);
		this.CANVAS_DIMENSIONS_Y =
			this.CRYSTAL_SIZE * this.YREPEATNUM +
			this.GAP * (this.YREPEATNUM - 1);
		this.p5 = 0;
	}

	componentDidMount() {
		//this.flowerGrower();
	}
	windowResized(p5) {
		console.log("resized!");
		//p5.resizeCanvas(windowWidth, windowHeight);

		this.breakpointSize();
		this.CANVAS_DIMENSIONS_X =
			this.CRYSTAL_SIZE * this.XREPEATNUM +
			this.GAP * (this.XREPEATNUM - 1);
		this.CANVAS_DIMENSIONS_Y =
			this.CRYSTAL_SIZE * this.YREPEATNUM +
			this.GAP * (this.YREPEATNUM - 1);
		p5.resizeCanvas(this.CANVAS_DIMENSIONS_X, this.CANVAS_DIMENSIONS_Y);
		p5.background("black");
		p5.fill("black");
		this.drawFunction(this.p5);
	}
	flowerGrower() {
		this.drawFunction(this.p5);
	}
	breakpointSize() {
		if (matchMedia("(min-width: 1400px)").matches) {
			this.XREPEATNUM = 5;
			this.YREPEATNUM = this.XREPEATNUM;
			this.CRYSTAL_SIZE = 100;

			// the viewport is at least 900 pixels wide
		} else if (matchMedia("(min-width: 1200px)").matches) {
			this.XREPEATNUM = 5;
			this.YREPEATNUM = this.XREPEATNUM;
			this.CRYSTAL_SIZE = 100;
			// the viewport is at least 900 pixels wide
		} else if (matchMedia("(min-width: 630px)").matches) {
			this.XREPEATNUM = 4;
			this.YREPEATNUM = this.XREPEATNUM;
			// the viewport is at least 900 pixels wide
		} else if (matchMedia("(min-width: 450px)").matches) {
			this.XREPEATNUM = 3;
			this.YREPEATNUM = this.XREPEATNUM;
			// the viewport is at least 900 pixels wide
		} else if (matchMedia("(min-width: 200px)").matches) {
			this.XREPEATNUM = 1;
			this.YREPEATNUM = 2;
			// the viewport is at least 900 pixels wide
		}
	}
	setUpFunction(p5, parentRef) {
		this.p5 = p5;
		this.breakpointSize();
		this.CANVAS_DIMENSIONS_X =
			this.CRYSTAL_SIZE * this.XREPEATNUM +
			this.GAP * (this.XREPEATNUM - 1);
		this.CANVAS_DIMENSIONS_Y =
			this.CRYSTAL_SIZE * this.YREPEATNUM +
			this.GAP * (this.YREPEATNUM - 1);
		p5.createCanvas(
			this.CANVAS_DIMENSIONS_X,
			this.CANVAS_DIMENSIONS_Y
		).parent(parentRef);
		p5.background("black");
		p5.fill("black");
		this.PALETTE = [
			[
				p5.color("rgba(211, 226, 219, .1"),
				p5.color("rgba(146, 213, 230, .7)"),
				p5.color("rgba(119, 45, 139, .7)"),
				p5.color("rgba(90, 11, 77, .8)"),
				p5.color("rgba(161, 239, 139, .9)"),
			],
			[
				p5.color("rgba(87, 77, 104, .7"),
				p5.color("rgba(163, 133, 96, .7)"),
				p5.color("rgba(198, 161, 91, .7)"),
				p5.color("rgba(242, 232, 109, .8)"),
				p5.color("rgba(211, 223, 184, .9)"),
			],
			[
				p5.color("rgba(133, 255, 158, .7"),
				p5.color("rgba(32, 181, 32, .7)"),
				p5.color("rgba(166, 244, 220, .7)"),
				p5.color("rgba(186, 89, 166, .8)"),
				p5.color("rgba(100, 63, 193, .7)"),
			],
			[
				p5.color("rgba(175, 36, 36, .6"),
				p5.color("rgba(33, 118, 255, .7)"),
				p5.color("rgba(51, 161, 253, .7)"),
				p5.color("rgba(253, 202, 64, .8)"),
				p5.color("rgba(247, 152, 36, .7)"),
			],
		];

		//default is radian, change to degree
		p5.angleMode(p5.DEGREES);
		//create rectanges from center, not corner
		p5.rectMode(p5.CENTER);
		//no animations, don't need to repeatedly render
		p5.noLoop();
	}
	drawShapes(p5, palette) {
		p5.push();
		//translate(width / 2, height / 2);
		p5.rotate(30);
		this.outlineShapeMaker(p5, palette);

		for (let i = 0; i < 10; i++) {
			this.shapesMaker(p5, 3, palette);
		}
		p5.pop();
	}

	outlineShapeMaker(p5, palette) {
		const strokeColor = palette[this.randomGetter(palette.length)];
		const weight = this.randomGetter(3);
		p5.stroke(strokeColor);
		p5.strokeWeight(weight);
		const hexagonTrue = this.randomGetter(2);

		if (hexagonTrue === 1) {
			//hexagon not working without svg library
			return;
		} else {
			p5.ellipse(0, 0, this.CRYSTAL_SIZE, this.CRYSTAL_SIZE);
		}
	}
	shapesMaker(p5, range, palette) {
		let numShapes = this.SIDES * (this.randomGetter(range) + 1);
		let shapesAngle = 360 / numShapes;

		//let strokeColor = palette[this.randomGetter(palette.length)];
		let fillColor = palette[this.randomGetter(palette.length)];
		let shapeSelector = this.randomGetter(4);
		let shapeSize = this.CRYSTAL_SIZE / (3 + this.randomGetter(2));
		let shapePosition = this.randomGetter(this.CRYSTAL_SIZE / 6);
		let shapeModifier = this.randomGetter(2) - 1.5;
		let shapeModifier2 = this.randomGetter(2) - 1.5;
		// console.log("numShapes: ", numShapes);
		// console.log("shapesAngle: ", shapesAngle);
		// console.log("strokeColor: ", strokeColor);
		// console.log("fillColor: ", fillColor);
		// console.log("shapeSelector: ", shapeSelector);
		// console.log("shapeSize: ", shapeSize);
		// console.log("shapePosition: ", shapePosition);
		// console.log("shapeModifier: ", shapeModifier);

		p5.push();
		for (let i = 0; i < numShapes; i++) {
			this.shapeGenerator(
				p5,
				fillColor,
				fillColor,
				shapeSelector,
				shapeSize,
				shapePosition,
				shapeModifier,
				shapeModifier2
			);
			p5.rotate(shapesAngle);
		}
		p5.pop();
	}
	shapeGenerator(
		p5,
		strokeColor,
		fillColor,
		shapeSelector,
		shapeSize,
		shapePosition,
		shapeModifier,
		shapeModifier2
	) {
		p5.push();
		p5.rectMode(p5.CENTER);
		p5.ellipseMode(p5.CENTER);
		p5.translate(0, shapePosition);
		p5.stroke(strokeColor);
		p5.fill(fillColor);
		switch (shapeSelector) {
			case 0:
				p5.ellipse(
					0,
					0,
					shapeSize * shapeModifier,
					shapeSize * shapeModifier2
				);
				break;
			case 1:
				p5.triangle(
					0,
					0,
					0,
					shapeSize * shapeModifier,
					shapeSize * shapeModifier2,
					0
				);
				break;
			case 2:
				p5.rect(
					0,
					0,
					shapeSize * shapeModifier,
					shapeSize * shapeModifier2
				);
				break;
			case 3:
				p5.line(0, shapeSize, shapeSize, 0);
				break;
			case 4:
				p5.point(0, 0);
				break;
			default:
				console.log("uh oh, switch had an error");
		}
		p5.pop();
	}
	randomGetter(range) {
		const rando = this.p5.floor(this.p5.random(range));
		return rando;
	}
	drawFunction(p5) {
		let XREPEATNUM = this.XREPEATNUM;
		let YREPEATNUM = this.YREPEATNUM;
		//let CANVAS_DIMENSIONS = this.CANVAS_DIMENSIONS;
		let widthGrid = this.CANVAS_DIMENSIONS_X / XREPEATNUM;
		let heightGrid = this.CANVAS_DIMENSIONS_Y / YREPEATNUM;
		//let heightGrid = height / YREPEATNUM;
		let x = widthGrid / 2;
		let y = heightGrid / 2;
		p5.push();
		p5.translate(x, y);
		console.log("YREPEATNUM: ", this.YREPEATNUM);
		console.log("heightGrid: ", heightGrid);
		let palette = this.PALETTE[this.randomGetter(this.PALETTE.length)];
		for (let i = 0; i < YREPEATNUM; i++) {
			p5.push();
			for (let j = 0; j < XREPEATNUM; j++) {
				//console.log("X: ", x);
				this.drawShapes(p5, palette);
				p5.translate(widthGrid, 0);
				//x += widthGrid;
			}
			p5.pop();
			//console.log("Y: ", y);
			p5.translate(0, heightGrid);
			//y += heightGrid;
		}
		p5.pop();
	}
	render() {
		return (
			<>
				<IonRow class="ion-justify-content-center">
					<IonCol></IonCol>
					<IonCol size="auto">
						<IonCard color="light" class="ion-margin dropShadow">
							<IonCardContent class="ion-no-padding">
								<Sketch
									setup={(p5, parentRef) => {
										this.setUpFunction(p5, parentRef);
									}}
									draw={(p5) => {
										this.drawFunction(p5);
									}}
									windowResized={(p5, parentRef) => {
										this.windowResized(p5, parentRef);
									}}
									className="displayImage3"
								></Sketch>
							</IonCardContent>
						</IonCard>
					</IonCol>
					<IonCol></IonCol>
				</IonRow>
				<IonRow>
					<IonCol class="center">
						<IonButton
							className="flowerButton"
							onClick={() => this.flowerGrower()}
						>
							{" "}
							<span role="img" aria-label="flower">
								🌼
							</span>
							&nbsp;&nbsp; Grow Some Flowers &nbsp;&nbsp;
							<span role="img" aria-label="flower">
								🌼
							</span>{" "}
							<IonRippleEffect></IonRippleEffect>
						</IonButton>
					</IonCol>
				</IonRow>
			</>
		);
	}
}

export default Flowers;
