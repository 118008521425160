import {
	IonRippleEffect,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonCardContent,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton,
	IonCardSubtitle,
} from "@ionic/react";
import React from "react";
import Unsplash, { toJson } from "unsplash-js";
//import firebase from '../FirebaseConfig';

const unsplash = new Unsplash({
	accessKey:
		"bcc171768f78f4e837667e42fb19d4ccd8eae75eb4f8801643e8319bda44667f",
});

class Goats extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			url: "",
			name: "null",
			userLink: "null",
		};
	}

	componentDidMount() {
		this.ImageUpdater();
		//const ref = firebase.firestore().collection;
	}

	ImageUpdater() {
		unsplash.photos
			.getRandomPhoto({
				collections: ["1243219,3643318,8997696,3471368"],
			})
			.then(toJson)
			.then((json) => {
				console.log(json);
				this.setState({
					url: json.urls.regular,
					name: json.user.name,
					userLink: json.links.html,
				});
			});
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar color="light">
						<IonTitle>
							{" "}
							<span role="img" aria-label="goat">
								🐐
							</span>{" "}
							Getter
						</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent color="medium">
					<IonGrid>
						<IonRow class="ion-justify-content-center">
							<IonCol></IonCol>
							<IonCol size="auto">
								<IonCard
									color="light"
									class="ion-margin dropShadow"
								>
									<IonCardContent class="ion-no-padding">
										<img
											src={this.state.url}
											alt="goat"
											className="displayImage"
										></img>
										<IonCardContent>
											<IonCardSubtitle id="Credit">
												Photo by{" "}
												<a href={this.state.userLink}>
													{this.state.name}
												</a>{" "}
												on{" "}
												<a href="https://unsplash.com/?utm_source=deepict&utm_medium=referral">
													{" "}
													Unsplash
												</a>
											</IonCardSubtitle>
										</IonCardContent>
									</IonCardContent>
								</IonCard>
							</IonCol>
							<IonCol></IonCol>
						</IonRow>
						<IonRow>
							<IonCol class="center">
								<IonButton onClick={() => this.ImageUpdater()}>
									Show me a goat
									<IonRippleEffect></IonRippleEffect>
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
	}
}

export default Goats;
