import { IonRippleEffect, IonButton } from "@ionic/react";
import React, { Fragment } from "react";
import firebase from "../FirebaseConfig";
import { v4 as uuidv4 } from "uuid";
import Jimp from "jimp";
import { dataURLToBlob } from "blob-util";

class ImageUpload extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			image: null,
			url: "",
			progress: 0
		};
	}

	componentDidMount() {
		console.log("upload button mounted");
	}

	handleChange(e) {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			document.getElementById(
				"parent"
			).innerHTML = `<div class="loader"></div> <div class="loading">Loading... </div>`;
			this.setState(
				() => ({ image }),
				() => {
					this.UploadFunction();
				}
			);
		}
	}

	async UploadFunction() {
		//const { image } = this.state;
		const imageUrl = URL.createObjectURL(this.state.image);
		let resizedImage = await this.imageResize(imageUrl, 1000);
		let sizedImageBlob = dataURLToBlob(resizedImage);
		let newImageUrl = URL.createObjectURL(sizedImageBlob);
		this.props.handleStateChange([newImageUrl]);
		const storage = firebase.storage();
		let uid = uuidv4();
		const uploadTask = storage.ref(`images/${uid}`).put(sizedImageBlob);
		uploadTask.on(
			"state_changed",
			snapshot => {
				// progress function ...
				const progress = Math.round(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				this.setState({ progress });
			},
			error => {
				// Error function ...
				console.log(error);
			},
			() => {
				// complete function ...
				storage
					.ref("images")
					.child(uid)
					.getDownloadURL()
					.then(url => {
						this.setState({ url }, () => {
							const db = firebase.firestore();
							const time = Date.now();
							db.collection("images")
								.doc(uid)
								.set({
									name: this.state.image.name,
									time: time,
									url: this.state.url,
									modifiedUrl: ""
								})
								.then(() => {
									this.props.handleStateChange([
										true,
										this.state.url,
										uid
									]);
									document.getElementById(
										"parent"
									).innerHTML = "Choose a Picture";
									console.log("docref=", uid);
								})
								.catch(error => {
									console.error("upload Error ", error);
								});
						});
					});
			}
		);
	}
	async imageResize(url, size) {
		let img = "read failed";
		await Jimp.read(url).then(async image => {
			img = await image
				.scaleToFit(size, size)
				.quality(40)
				.getBase64Async(Jimp.MIME_JPEG);
		});
		return img;
	}

	render() {
		return (
			<Fragment>
				<IonButton
					id="parent"
					onClick={() => document.getElementById("file").click()}
					fill="outline"
					class="fancyButton"
				>
					Choose a Picture
				</IonButton>
				<IonRippleEffect></IonRippleEffect>
				<input
					type="file"
					onChange={e => this.handleChange(e)}
					accept=".jpg, .jpeg, .png"
					name="file"
					id="file"
					className="inputfile"
				/>
			</Fragment>
		);
	}
}

export default ImageUpload;
