import {
	IonRippleEffect,
	IonRow,
	IonCol,
	IonGrid,
	IonCard,
	IonCardContent,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButton
} from "@ionic/react";
import React from "react";
//import firebase from '../FirebaseConfig';
import firebase from "../FirebaseConfig";
import ImageUpload from "../Components/ImageUpload";
class Nuke extends React.Component {
	constructor(props) {
		super(props);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.state = {
			url: "assets/images/orange.gif",
			imageAdded: "",
			image: "",
			uid: "",
			modifiedUrl: ""
		};
	}

	componentDidMount() {
		//this.ImageUpdater();
		//const ref = firebase.firestore().collection;
	}

	handleStateChange(value) {
		if (value.length > 1) {
			let imageAdded = this.state.imageAdded;
			let url = this.state.url;
			let uid = this.state.uid;
			imageAdded = value[0];
			url = value[1];
			uid = value[2];
			this.setState({
				imageAdded: imageAdded,
				url: url,
				uid: uid
			});
		} else {
			let image = this.state.image;
			image = value[0];
			this.setState({
				image: image,
				modifiedUrl: "",
				imageAdded: ""
			});
		}
	}

	async ImageModifier() {
		//get state uid
		let uid = this.state.uid;
		document.getElementById(
			"nukeButton"
		).innerHTML = `<div class="loader"></div> <div class="loading">Nuking... </div>`;
		//send http request to cloud function
		let grabbed = await fetch(
			`https://us-central1-deepict-736c6.cloudfunctions.net/nuke?uid=${uid}`
		);
		//check if response is true
		let grabbedCheck = await grabbed.json();
		console.log("test", grabbedCheck);
		if (grabbedCheck === true) {
			const storage = firebase.storage();
			storage
				.ref("modified_images")
				.child(`${uid}-modified`)
				.getDownloadURL()
				.then(modifiedUrl => {
					this.setState({ modifiedUrl }, () => {
						const db = firebase.firestore();
						db.collection("images")
							.doc(uid)
							.update({
								modifiedUrl: this.state.modifiedUrl
							});
						document.getElementById(
							"nukeButton"
						).innerHTML = ` <span role="img" aria-label="fire">🔥</span>  <span role="img" aria-label="nuke">☢️</span>  <span role="img" aria-label="bang">💥</span> Nuke <span role="img" aria-label="bang">💥</span>  <span role="img" aria-label="nuke">☢️</span>  <span role="img" aria-label="fire">🔥</span> `;
					});
				});
		} else {
			console.log("http request failed");
		}
		//if true create modifiedUrl
		//add modifiedUrl to state
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar color="light">
						<IonTitle>
							{" "}
							<span role="img" aria-label="fire">
								🔥
							</span>{" "}
							<span role="img" aria-label="fire">
								🔥
							</span>{" "}
							<span role="img" aria-label="nuke">
								☢️
							</span>{" "}
							<span role="img" aria-label="fire">
								🔥
							</span>{" "}
							<span role="img" aria-label="fire">
								🔥
							</span>{" "}
						</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent color="medium">
					<IonGrid>
						<IonRow class="ion-justify-content-center">
							<IonCol></IonCol>
							<IonCol size="auto">
								<IonCard
									color="light"
									class="ion-margin dropShadow displayImage"
								>
									<IonCardContent class="ion-no-padding">
										<img
											src={
												this.state.modifiedUrl ||
												this.state.image ||
												this.state.url
											}
											alt="nuked nuke"
											className="displayImage"
										></img>
									</IonCardContent>
								</IonCard>
							</IonCol>
							<IonCol></IonCol>
						</IonRow>
						<IonRow>
							<IonCol class="center">
								<ImageUpload
									handleStateChange={this.handleStateChange}
								></ImageUpload>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol class="center">
								<IonButton
									disabled={!this.state.imageAdded}
									id="nukeButton"
									class="nukeButton"
									onClick={() => this.ImageModifier()}
								>
									{" "}
									<span role="img" aria-label="fire">
										🔥
									</span>{" "}
									<span role="img" aria-label="nuke">
										☢️
									</span>{" "}
									<span role="img" aria-label="bang">
										💥
									</span>{" "}
									Nuke{" "}
									<span role="img" aria-label="bang">
										💥
									</span>{" "}
									<span role="img" aria-label="nuke">
										☢️
									</span>{" "}
									<span role="img" aria-label="fire">
										🔥
									</span>{" "}
									<IonRippleEffect></IonRippleEffect>
								</IonButton>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
	}
}

export default Nuke;
