import * as firebase from 'firebase';
import 'firebase/firestore';


const config = {
  apiKey: "AIzaSyCy48OtdkIiVwvuiKZ8hA5aRgWVx2StCRA",
  authDomain: "deepict-736c6.firebaseapp.com",
  databaseURL: "https://deepict-736c6.firebaseio.com",
  projectId: "deepict-736c6",
  storageBucket: "deepict-736c6.appspot.com",
  messagingSenderId: "403911012712",
  appId: "1:403911012712:web:644dd3a00c0453433fa6dd",
  measurementId: "G-K6DB7E1EML"
};
firebase.initializeApp(config);


export default firebase;