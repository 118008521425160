import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
	IonText,
	IonIcon,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonButton,
} from "@ionic/react";
import { cloudUploadOutline } from "ionicons/icons";
import firebase from "../FirebaseConfig";
import Jimp from "jimp";
import { dataURLToBlob } from "blob-util";
export default function DropZone(props) {
	let cardTitle = props.directory === "oPic" ? "Original Pic" : "Style Pic";

	const toggle = useCallback(() => {
		var image = document.getElementById(`${props.directory}Images`);
		var dropZone = document.getElementById(`${props.directory}DropZone`);
		var button = document.getElementById(`${props.directory}Button`);
		if (image.style.display === "none") {
			image.style.display = "block";
			button.style.display = "block";
			dropZone.style.display = "none";
		} else {
			props.handleStateChange([-1]);
			image.style.display = "none";
			button.style.display = "none";
			dropZone.style.display = "block";
		}
	}, [props]);

	async function webpConverter(webP) {
		var tempCanvas = document.createElement("canvas");
		var ctx = tempCanvas.getContext("2d");
		tempCanvas.width = webP.width;
		tempCanvas.height = webP.height;
		ctx.drawImage(webP, 0, 0);
		console.log("webP: ", webP);
		console.log(tempCanvas);
		console.log(tempCanvas.toDataURL("image/jpeg"));
		return tempCanvas.toDataURL("image/jpeg");
	}

	async function imageResize(url, size) {
		let img = "read failed";

		await Jimp.read(url).then(async (image) => {
			if (size >= 10) {
				img = await image
					.scaleToFit(size, size)
					.quality(80)
					.getBase64Async(Jimp.MIME_JPEG);
			} else {
				img = await image
					.scaleToFit(size, size)
					.quality(80)
					.getBufferAsync(Jimp.MIME_JPEG);
				// 	, (err, buffer) => {
				// 	if (err) throw err;
				// 	else {
				// 		console.log("buffer: ", buffer);
				// 		test = buffer;
				// 	}
				// });
			}
		});
		return img;

		// .catch(err => {
		// 	console.log(err);
		// });
	}

	const uploadFunction = useCallback(
		async (uid, url, image, type, tempImg) => {
			if (type === "image/webp") {
				url = await webpConverter(tempImg);
			}
			const [thumb, sizedImage] = await Promise.all([
				await imageResize(url, 100),
				props.directory === "oPic"
					? await imageResize(url, 600)
					: await imageResize(url, 200),

				// if (props.directory === "oPic") {
				// 	await imageResize(url, 1000);
				// }
				// else {
				// 	await imageResize(url, 500)
				// }
			]);
			let sizedImageBlob = dataURLToBlob(sizedImage);

			let directory = props.directory;
			const storage = firebase.storage();

			const uploadTask = storage
				.ref(`${directory}/${uid}`)
				.put(sizedImageBlob);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					// progress function ...
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					console.log("progress: ", progress);
					//this.setState({ progress });
				},
				(error) => {
					console.log(error); // Error function ...
				},
				() => {
					// complete function ...

					storage
						.ref(directory)
						.child(uid)
						.getDownloadURL()
						.then((url) => {
							props.handleStateChange([
								1,
								directory,
								url,
								image.name,
								thumb,
								sizedImage,
							]);
						})
						.catch((error) => {
							console.log(error);
						});
				}
			);
		},
		[props]
	);
	const onDrop = useCallback(
		(acceptedFiles) => {
			let uid = props.uid;
			//url of picture that gets dropped
			const objectURL = URL.createObjectURL(acceptedFiles[0]);
			//set hidden image on top of dropzone to that of the url
			let type = acceptedFiles[0].type;
			console.log("type: ", type);
			let tempImg = new Image();
			tempImg.src = objectURL;

			document.getElementById(`${props.directory}Images`).src = objectURL;
			//switch between the image and the dropzone
			toggle();

			//upload the picture to cloud storage and create a db reference to it
			tempImg.onload = function () {
				uploadFunction(uid, objectURL, acceptedFiles[0], type, tempImg);
			};
		},
		[props.directory, props.uid, toggle, uploadFunction]
	);

	//drop zone styling
	//----------------------------------------------------------------------------------------------------

	const baseStyle = {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: "20px",
		borderWidth: 5,
		borderRadius: 5,
		borderColor: "#c6c6cc",
		borderStyle: "solid",
		borderBottom: "0",
		backgroundColor: "#dfdfe6",
		outline: "none",
		transition: "border .24s ease-in-out",
	};

	const borderStyle = {
		borderWidth: 0,
		borderBottom: 3,
		borderRadius: 7,
		borderColor: "#cecece",
		borderStyle: "solid",
	};
	const activeStyle = {
		borderColor: "#a0c0d9",
		backgroundColor: "#bbd7ed",
	};

	const acceptStyle = {
		borderColor: "#a0c0d9",
	};

	const rejectStyle = {
		borderColor: "#e09999",
		backgroundColor: "#e3c1c1",
	};
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
		// } = useDropzone({ accept: "image/jpeg, image/png, image/webp", onDrop });
	} = useDropzone({ onDrop });

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[
			isDragActive,
			isDragReject,
			acceptStyle,
			activeStyle,
			baseStyle,
			isDragAccept,
			rejectStyle,
		]
	);

	//end styling
	//----------------------------------------------------------------------------------------

	return (
		<IonCard color="light" className="dropShadow stCard">
			<IonCardHeader class="ion-no-padding">
				<div className="stCardHeader">
					<IonText
						className="stCardHeaderChildren stCardHeaderText"
						color="primary"
					>
						<h5 className="ion-no-margin">{cardTitle}</h5>
					</IonText>
					<IonButton
						id={`${props.directory}Button`}
						class="ion-no-margin"
						className="stCardHeaderChildren stCardHeaderUndo"
						fill="clear"
						color="secondary"
						style={{ display: "none" }}
						onClick={() => {
							setTimeout(toggle, 400);
						}}
					>
						Undo
					</IonButton>
				</div>
			</IonCardHeader>
			<IonCardContent>
				<div className="container">
					<img
						className="stImages"
						id={`${props.directory}Images`}
						style={{ display: "none" }}
						src=""
						alt="Style Transfer"
					></img>
					<div id={`${props.directory}DropZone`} className="dropZone">
						<div
							className="center innerDropZone"
							style={borderStyle}
						>
							<div
								className=" innerShadow center innerDropZone"
								{...getRootProps({ style })}
							>
								<input {...getInputProps()} />
								<IonIcon
									className="uploadIcon uploadInner"
									color="medium"
									icon={cloudUploadOutline}
								></IonIcon>
								<IonText
									className="uploadInner"
									color="secondary"
								>
									Drag 'n drop a picture here, <br />
									or click to select one
								</IonText>
							</div>
						</div>
					</div>
				</div>
			</IonCardContent>
		</IonCard>
	);
}
