import {
	IonRow,
	IonCol,
	IonGrid,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonCard,
	IonCardContent,
} from "@ionic/react";
import React from "react";
import firebase from "../FirebaseConfig";
//import DropZone from "../Components/DropZone";
import Flowers from "../Components/Flowers";
import { v4 as uuidv4 } from "uuid";

class GenArt extends React.Component {
	constructor(props) {
		super(props);
		let uid = uuidv4();

		this.tempCanvas = document.createElement("canvas");
		this.tempCanvas.id = "tempCanvas";

		this.handleStateChange = this.handleStateChange.bind(this);

		this.state = {
			uid: uid,
			imagesAdded: 0,
			disableButton: false,
			cancelledFlag: false,
		};
	}

	componentDidMount() {
		document.getElementById("genArtDisplay").style.display = "none";
	}

	handleStateChange(value) {
		if (value.length > 1) {
			let imageAdded = this.state.imageAdded;
			let url = this.state.url;
			let uid = this.state.uid;
			imageAdded = value[0];
			url = value[1];
			uid = value[2];
			this.setState({
				imageAdded: imageAdded,
				url: url,
				uid: uid,
			});
		} else {
			let image = this.state.image;
			image = value[0];
			this.setState({
				image: image,
				modifiedUrl: "",
				imageAdded: "",
			});
		}
	}

	async ImageModifier() {
		//get state uid
		let uid = this.state.uid;
		document.getElementById(
			"nukeButton"
		).innerHTML = `<div class="loader"></div> <div class="loading">Nuking... </div>`;
		//send http request to cloud function
		let grabbed = await fetch(
			`https://us-central1-deepict-736c6.cloudfunctions.net/nuke?uid=${uid}`
		);
		//check if response is true
		let grabbedCheck = await grabbed.json();
		console.log("test", grabbedCheck);
		if (grabbedCheck === true) {
			const storage = firebase.storage();
			storage
				.ref("modified_images")
				.child(`${uid}-modified`)
				.getDownloadURL()
				.then((modifiedUrl) => {
					this.setState({ modifiedUrl }, () => {
						const db = firebase.firestore();
						db.collection("images")
							.doc(uid)
							.update({
								modifiedUrl: this.state.modifiedUrl,
							});
						document.getElementById(
							"nukeButton"
						).innerHTML = ` <span role="img" aria-label="fire">🔥</span>  <span role="img" aria-label="nuke">☢️</span>  <span role="img" aria-label="bang">💥</span> Nuke <span role="img" aria-label="bang">💥</span>  <span role="img" aria-label="nuke">☢️</span>  <span role="img" aria-label="fire">🔥</span> `;
					});
				});
		} else {
			console.log("http request failed");
		}
		//if true create modifiedUrl
		//add modifiedUrl to state
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar color="light">
						<IonTitle>
							{" "}
							<span role="img" aria-label="alien">
								👽
							</span>{" "}
							<span role="img" aria-label="flower">
								🌼
							</span>{" "}
							<span role="img" aria-label="brush">
								🖌️
							</span>{" "}
						</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent color="medium">
					<IonGrid>
						<IonRow class="ion-justify-content-center">
							<IonCol id="genArtDisplay">
								<IonCard
									color="light"
									class="ion-margin dropShadow "
								>
									<IonCardContent
										className="displayImage2"
										class="ion-no-padding"
									>
										<a
											href={this.state.tPicUrl}
											download={`${this.state.oPicName}-styled.jpeg`}
										>
											<img
												src={
													this.state.tPicUrl ||
													"assets/images/drawingLoading.gif"
												}
												alt="Style Transferred"
												className="displayImage"
											></img>
										</a>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
						<IonRow class="ion-justify-content-center ion-align-items-top">
							<IonCol className="dropZones" size="auto">
								<Flowers
								// uid={this.state.uid}
								// handleStateChange={this.handleStateChange}
								// directory="flowers"
								></Flowers>
							</IonCol>
						</IonRow>
						{/* <IonRow>
							<IonCol class="center">
								<IonButton
									disabled={
										!(this.state.imagesAdded === 2) ||
										this.state.disableButton === true
									}
									id="stButton"
									class="stButton"
									onClick={() => this.buttonPicker()}
								>
									Begin Style Transfer
									<IonRippleEffect></IonRippleEffect>
								</IonButton>
							</IonCol>
						</IonRow> */}
					</IonGrid>
				</IonContent>
			</IonPage>
		);
	}
}

export default GenArt;
