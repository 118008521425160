import {
	IonRippleEffect,
	IonRow,
	IonCol,
	IonGrid,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonToggle,
	IonButton,
	IonCard,
	IonCardContent,
} from "@ionic/react";
import React from "react";
import firebase from "../FirebaseConfig";
import DropZone from "../Components/DropZone";
import { v4 as uuidv4 } from "uuid";

const magenta = require("@magenta/image");
class StyleTransfer extends React.Component {
	constructor(props) {
		super(props);
		let uid = uuidv4();

		this.tempCanvas = document.createElement("canvas");
		this.tempCanvas.id = "tempCanvas";
		this.model = new magenta.ArbitraryStyleTransferNetwork();
		this.handleStateChange = this.handleStateChange.bind(this);

		this.state = {
			uid: uid,
			imagesAdded: 0,
			stPicker: false,
			oldStPicker: "",
			oPicData: "",
			oPicName: "",
			oPicUrl: "",
			oPicThumb: "",
			sPicData: "",
			sPicName: "",
			sPicUrl: "",
			sPicThumb: "",
			tPicData: "",
			tPicName: "",
			tPicUrl: "",
			oldOPic: "",
			oldSPic: "",
			disableButton: false,
			cancelledFlag: false,
		};
	}

	componentDidMount() {
		document.getElementById("styleTransferDisplay").style.display = "none";
		var thumbnails = document.getElementsByClassName("thumbNails");

		this.model.initialize();

		for (let thumbnail of thumbnails) {
			thumbnail.style.display = "none";
		}
	}
	componentWillUnmount() {
		this.model.dispose();
	}
	stPicker() {
		if (this.state.stPicker === false) {
			this.setState({ stPicker: true });
			document.getElementById("Slow").style.color = "White";
			document.getElementById("Fast").style.color = "LightGray";
			console.log(true);
		} else {
			this.setState({ stPicker: false });
			document.getElementById("Fast").style.color = "White";
			document.getElementById("Slow").style.color = "LightGray";
			console.log(false);
		}
	}

	async clearCanvas() {
		// Don't block painting until we've reset the state.
		await magenta.tf.nextFrame();
		this.tempCanvas
			.getContext("2d")
			.clearRect(0, 0, this.tempCanvas.width, this.tempCanvas.height);
		await magenta.tf.nextFrame();
	}

	async cloudImageModifier(uid) {
		let grabbed = await fetch(
			`https://us-central1-deepict-736c6.cloudfunctions.net/stylize?uid=${uid}`
		);
		if (grabbed) {
			let data = await grabbed.text();
			if (this.state.cancelledFlag === false) {
				this.setState({ tPicUrl: data, tPicName: uid });
			}
		} else {
			console.log("something went wrong");
		}
		//console.log("test");
	}

	async buttonPicker() {
		this.setState({ disableButton: true, cancelledFlag: false });
		setTimeout(() => {
			this.setState({ disableButton: false });
		}, 300);
		if (document.getElementById("stButton").innerHTML.includes("Working")) {
			this.setState({ cancelledFlag: true, oldOPic: "", oldSPic: "" });
			this.toggle();
			console.log("cancelled");
			document.getElementById(
				"stButton"
			).innerHTML = ` Begin Style Transfer `;
			document.getElementById("downloadInstructions").innerHTML = "";
			return;
		}
		if (
			this.state.oPicData !== this.state.oldOPic ||
			this.state.sPicData !== this.state.oldSPic ||
			this.state.stPicker !== this.state.oldStPicker
		) {
			let uid = this.state.uid;
			await this.createDatabaseRef();

			var styleTransferDisplay = document.getElementById(
				"styleTransferDisplay"
			);
			this.setState({
				tPicUrl: "",
				cancelledFlag: false,
			});
			this.toggle();
			if (styleTransferDisplay.style.display !== "none") {
				document.getElementById(
					"stButton"
				).innerHTML = `<div class="loader"></div> <div class="loading">Working... </div>`;

				if (this.state.stPicker === false) {
					await this.cloudImageModifier(uid);
				} else {
					await this.localImageModifier();
				}
				if (styleTransferDisplay.style.display !== "none") {
					document.getElementById("stButton").innerHTML = ` Go Back `;
					document.getElementById("downloadInstructions").innerHTML =
						"click the picture to download";
				}
				this.state.oldOPic = this.state.oPicData;
				this.state.oldSPic = this.state.sPicData;
				this.state.oldStPicker = this.state.stPicker;
				console.log("pics aren't same");
			} else {
				this.setState({ tPicUrl: "" });
				document.getElementById("downloadInstructions").innerHTML = "";
				document.getElementById(
					"stButton"
				).innerHTML = ` Begin Style Transfer `;
			}
		} else {
			this.toggle();
			console.log("pics are same");
			if (document.getElementById("stButton").innerHTML === ` Go Back `) {
				document.getElementById(
					"stButton"
				).innerHTML = ` Begin Style Transfer `;
				document.getElementById("downloadInstructions").innerHTML = "";
			} else {
				document.getElementById("downloadInstructions").innerHTML =
					"click the picture to download";
				document.getElementById("stButton").innerHTML = ` Go Back `;
			}
		}
	}

	workerTest() {
		this.worker.postMessage([this.state.oPicData, this.state.sPicData]);
		console.log("in workerTest");
		this.worker.onmessage = (e) => {
			console.log(e.data);
		};
	}

	async stylizer(contentImg, styleImg) {
		await this.clearCanvas();
		let finalImage;
		await this.model
			.stylize(contentImg, styleImg)
			.then(async (imageData) => {
				this.tempCanvas.width = contentImg.width;
				this.tempCanvas.height = contentImg.height;
				this.tempCanvas.getContext("2d").putImageData(imageData, 0, 0);

				finalImage = this.tempCanvas.toDataURL("image/jpeg");
			})
			.catch((reason) => {
				console.log(reason);
			});

		return finalImage;
	}

	toggle() {
		var styleTransferDisplay = document.getElementById(
			"styleTransferDisplay"
		);
		var dropZones = document.getElementsByClassName("dropZones");
		var thumbnails = document.getElementsByClassName("thumbNails");
		if (styleTransferDisplay.style.display === "none") {
			styleTransferDisplay.style.display = "inherit";
			for (let dropZone of dropZones) {
				dropZone.style.display = "none";
			}
			for (let thumbnail of thumbnails) {
				thumbnail.style.display = "initial";
			}
		} else {
			styleTransferDisplay.style.display = "none";
			for (let dropZone of dropZones) {
				dropZone.style.display = "initial";
			}
			for (let thumbnail of thumbnails) {
				thumbnail.style.display = "none";
			}
		}
	}

	async createDatabaseRef() {
		const db = firebase.firestore();
		const time = Date.now();
		const uid = this.state.uid;
		await db
			.collection("stImages")
			.doc(uid)
			.set({
				Orignal_Picture_Name: this.state.oPicName,
				Original_Picture_Url: this.state.oPicUrl,
				Style_Picture_Name: this.state.sPicName,
				Style_Picture_Url: this.state.sPicUrl,
				Transfer_Picture_Url: "",
				time: time,
			})
			.then(() => {
				console.log("database ref created!");
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleStateChange(value) {
		let imagesAdded = this.state.imagesAdded;
		imagesAdded += value[0];
		const whichPic = value[1];
		const url = value[2];
		const imageName = value[3];
		const thumb = value[4];
		const data = value[5];
		const whichUrl = `${whichPic}Url`;
		const whichName = `${whichPic}Name`;
		const whichThumb = `${whichPic}Thumb`;
		const whichData = `${whichPic}Data`;
		this.setState({
			imagesAdded: imagesAdded,
			[whichUrl]: url,
			[whichName]: imageName,
			[whichThumb]: thumb,
			[whichData]: data,
		});
	}

	async localImageModifier() {
		document.getElementById(
			"stButton"
		).innerHTML = `<div class="loader"></div> <div class="loading">Working... </div>`;

		let contentImg = new Image();

		let styleImg = new Image();
		console.log("oPicData: ", this.state.oPicData);
		await Promise.all([
			new Promise((resolve, reject) => {
				contentImg.onload = function() {
					resolve(console.log("contentImg loaded"));
				};
				contentImg.onerror = reject;
				contentImg.src = this.state.oPicData;
			}),
			new Promise((resolve, reject) => {
				styleImg.onload = function() {
					resolve(console.log("styleImg loaded"));
				};
				styleImg.onerror = reject;
				styleImg.src = this.state.sPicData;
			}),
		]).catch((err) => console.log(err));
		let finalImg = await this.stylizer(contentImg, styleImg);
		console.log("finalImg: ", finalImg);
		let uid = this.state.uid;
		if (this.state.cancelledFlag === false) {
			this.setState({ tPicUrl: finalImg, tPicName: uid });
		}
	}

	render() {
		return (
			<IonPage>
				<IonHeader>
					<IonToolbar color="light">
						<IonTitle>
							{" "}
							<span role="img" aria-label="pallete">
								🎨
							</span>{" "}
							<span role="img" aria-label="robot">
								🤖
							</span>{" "}
						</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent color="medium">
					<IonGrid>
						<IonRow class="ion-justify-content-center ion-align-items-center">
							<IonCol className="thumbNails" size="auto">
								<a
									href={`${this.state.oPicUrl}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<IonCard
										color="light"
										className="thumbnailCardContent"
									>
										<img
											src={this.state.oPicThumb}
											alt="Original Pic"
											className="thumbnail"
										></img>
									</IonCard>
								</a>
							</IonCol>
							<IonCol
								size="auto"
								className="thumbNails thumbnail"
							>
								<h3>+</h3>
							</IonCol>
							<IonCol className="thumbNails" size="auto">
								<a
									href={`${this.state.sPicUrl}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									<IonCard
										color="light"
										className="thumbnailCardContent"
									>
										<img
											src={this.state.sPicThumb}
											alt="Style Pic"
											className="thumbnail"
										></img>
									</IonCard>
								</a>
							</IonCol>
						</IonRow>
						<IonRow class="ion-justify-content-center">
							<IonCol id="styleTransferDisplay">
								<IonCard
									color="light"
									class="ion-margin dropShadow "
								>
									<IonCardContent
										className="displayImage2"
										class="ion-no-padding"
									>
										<a
											href={this.state.tPicUrl}
											download={`${this.state.oPicName}-styled.jpeg`}
										>
											<img
												src={
													this.state.tPicUrl ||
													"assets/images/drawingLoading.gif"
												}
												alt="Style Transferred"
												className="displayImage"
											></img>
										</a>
									</IonCardContent>
								</IonCard>
							</IonCol>
						</IonRow>
						<IonRow class="ion-justify-content-center ion-align-items-top">
							<IonCol className="dropZones" size="auto">
								<DropZone
									uid={this.state.uid}
									handleStateChange={this.handleStateChange}
									directory="oPic"
								></DropZone>
							</IonCol>

							<IonCol className="dropZones" size="auto">
								<DropZone
									uid={this.state.uid}
									handleStateChange={this.handleStateChange}
									directory="sPic"
								></DropZone>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol
								id="downloadInstructions"
								class="center"
							></IonCol>
						</IonRow>
						<IonRow>
							<IonCol class="center">
								<IonButton
									disabled={
										!(this.state.imagesAdded === 2) ||
										this.state.disableButton === true
									}
									id="stButton"
									class="stButton"
									onClick={() => this.buttonPicker()}
								>
									Begin Style Transfer
									<IonRippleEffect></IonRippleEffect>
								</IonButton>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol class="center">
								<span id="Fast">Server</span>
								<IonToggle
									className="stPicker"
									onIonChange={(e) => this.stPicker(e)}
								/>
								<span id="Slow">Browser</span>
							</IonCol>
						</IonRow>
						<IonRow>
							<IonCol class="center ion-hide-md-up warning">
								Note: If on mobile, choosing browser will
								probably crash the app.
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonPage>
		);
	}
}

export default StyleTransfer;
