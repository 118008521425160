import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
	IonApp,
	IonLabel,
	IonRouterOutlet,
	IonTabBar,
	IonTabButton,
	IonIcon,
	IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
//import { ellipse, square, triangle } from 'ionicons/icons';
import Goats from "./pages/Goats";
import Nuke from "./pages/Nuke";
import StyleTransfer from "./pages/StyleTransfer";
import GenArt from "./pages/GenArt";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { nuclear, flask, brush } from "ionicons/icons";
const App: React.FC = () => (
	<IonApp>
		<IonReactRouter>
			<IonTabs>
				<IonRouterOutlet>
					<Route
						path="/styleTransfer"
						component={StyleTransfer}
						exact={true}
					/>
					<Route path="/goats" component={Goats} exact={true} />
					<Route path="/nuke" component={Nuke} exact={true} />
					<Route path="/generative" component={GenArt} exact={true} />
					<Route
						path="/"
						render={() => <Redirect to="/styleTransfer" />}
						exact={true}
					/>
				</IonRouterOutlet>
				<IonTabBar slot="bottom">
					<IonTabButton tab="styleTransfer" href="/styleTransfer">
						<IonIcon icon={flask}> </IonIcon>
						<IonLabel>Style Transfer</IonLabel>
					</IonTabButton>
					<IonTabButton tab="goats" href="/goats">
						<IonIcon class="goatIcon" src="/assets/GoatFilled.svg">
							{" "}
						</IonIcon>
						<IonLabel>Goats</IonLabel>
					</IonTabButton>
					<IonTabButton tab="nuke" href="/nuke">
						<IonIcon icon={nuclear} />
						<IonLabel>Nuke</IonLabel>
					</IonTabButton>
					<IonTabButton tab="gen" href="/generative">
						<IonIcon icon={brush} />
						<IonLabel>Gen Art</IonLabel>
					</IonTabButton>
				</IonTabBar>
			</IonTabs>
		</IonReactRouter>
	</IonApp>
);

export default App;
